export default [
  {
    title: 'Tableau de bord',
    route: 'espace-affreteur.dashboard',
    icon: 'HomeIcon',
  },
  {
    header: 'Gestion des Offres',
    icon: 'PackageIcon',
  },
  {
    title: 'Créer un fret ',
    icon: 'PlusIcon',
    route: 'espace-affreteur.nouvelle.offre',
  },
  {
    header: 'Gestion des demandes d\'expertise',
    icon: 'PackageIcon',
  },
  {
    title: 'Mes demandes d\'expertise',
    icon: 'ListIcon',
    children: [
      {
        title: 'Poser une question',
        color: 'primary',
        icon: 'DiscIcon',

        route: 'espace-affreteur.demande-expertise',

      },
      {
        title: 'Mes demandes',
        color: 'info',
        icon: 'DiscIcon',

        route: 'espace-affreteur.expertises-soumises',

      },
      {
        title: 'Expertises traitées',
        color: 'success',
        icon: 'DiscIcon',

        route: 'espace-affreteur.expertises-traitees',

      },

    ],
  },
  {
    title: 'Etat des offres',
    icon: 'ListIcon',
    children: [
      {
        title: 'Offres en attente',
        route: 'espace-affreteur.new',
        icon: 'DiscIcon',
        color: 'info',
      },
      {
        title: 'Offres en négociation',
        route: 'espace-affreteur.created.offre',
        icon: 'DiscIcon',
        color: 'warning',
      },

      {
        title: 'Offres clôturées',
        route: 'espace-affreteur.closed.offre',
        icon: 'DiscIcon',
        color: 'success',
      },
      {
        title: 'Offre rejetée',
        route: 'espace-affreteur.canceled.offre',
        icon: 'DiscIcon',
        color: 'danger',
      },
    ],
  },
  {
    header: 'Gestion de plainte',
    icon: 'PackageIcon',
  },
  {
    title: 'Gestion plaintes',
    icon: 'ListIcon',
    children: [
      {
        title: 'Ajouter une plainte',
        color: 'primary',
        icon: 'DiscIcon',

        route: 'espace-affreteur.soumettre-plainte',

      },
      {
        title: 'Mes plaintes',
        color: 'info',
        icon: 'DiscIcon',

        route: 'espace-affreteur.plaintes-soumis',

      },
      {
        title: 'Plaintes traitées',
        color: 'success',
        icon: 'DiscIcon',

        route: 'espace-affreteur.plaintes-traitees',

      },

    ],
  },
  {
    header: 'Gestion des devis',
    icon: 'PackageIcon',
  },

  {
    title: 'Etat des devis',
    icon: 'ListIcon',
    children: [
      {
        title: 'Devis reçus',
        route: 'espace-affreteur.devis',
        icon: 'DiscIcon',
        color: 'primary',
      },
      {
        title: 'Devis acceptés',
        route: 'espace-affreteur.devis-acceptes',
        icon: 'DiscIcon',
        color: 'success',
      },
      {
        title: 'Devis rejetés',
        route: 'espace-affreteur.devis-rejet',
        icon: 'DiscIcon',
        color: 'danger',
      },

    ],
  },
  {
    header: 'Facturation',
    icon: 'PackageIcon',
  },
  {
    title: 'Liste des factures',
    icon: 'ListIcon',
    route: 'espace-affreteur.liste-facture',

  },

  {
    header: 'Diffusion',
    icon: 'PackageIcon',

  },
  {
    title: 'Réseau de affreteurs',
    route: 'espace-affreteur.diffusion',
    icon: 'ListIcon',

  },

  {
    header: 'Gestion du tracking',
    icon: 'PackageIcon',
  },
  {
    title: 'Suivi de la cargaison',
    icon: 'MapPinIcon',
    route: 'espace-affreteur.tracking',
  },
  {
    header: 'Gestion de profil',
    icon: 'PackageIcon',
  },
  {
    title: 'Activation de compte',
    icon: 'CheckCircleIcon',
    color: 'success',
    route: 'espace-affreteur.activate-account',
  },

]
